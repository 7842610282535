<script setup lang="ts">
import type { CmsSectionDefault } from "@shopware-pwa/composables-next";
import { getCmsLayoutConfiguration } from "@shopware-pwa/helpers-next";

const props = defineProps<{
  content: CmsSectionDefault;
}>();

const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(props.content);

const rounded = props.content.customFields?.roundedTop === true;
if (rounded) {
  cssClasses["rounded-t-xxl"] = true;
}

const backgroundColorClass = props.content.customFields?.backgroundColorClass;
if (backgroundColorClass) {
  cssClasses[backgroundColorClass] = true;
} else {
  cssClasses["bg-background"] = true;
}

const isFullWidth = computed(() => {
  return layoutStyles.sizingMode == "full_width";
});

if (layoutStyles.backgroundImage) {
  layoutStyles.backgroundPositionX =
    props?.content?.customFields?.backgroundPositionX ?? "center";
  layoutStyles.backgroundPositionY =
    props?.content?.customFields?.backgroundPositionY ?? "center";
}
</script>

<template>
  <div class="cms-section-default" :class="cssClasses" :style="layoutStyles">
    <v-container
      :fluid="isFullWidth"
      :class="{
        'px-0': isFullWidth,
      }"
    >
      <CmsGenericBlock
        v-for="cmsBlock in content.blocks"
        :key="cmsBlock.id"
        class=""
        :content="cmsBlock"
      />
    </v-container>
  </div>
</template>
